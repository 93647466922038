import React from "react"
import { Button } from "grommet"

const IconButton = ({ children, hoverIndicator = true, ...rest }) => (
  <Button
    icon={children}
    hoverIndicator={hoverIndicator}
    border="round"
    size="medium"
    {...rest}
  />
)

export default IconButton
