import { graphql } from "gatsby"
import React from "react"
import { Box, Heading, Paragraph, ResponsiveContext } from "grommet"
import { MDXRenderer } from "gatsby-plugin-mdx"
import moment from "moment-timezone"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import Wrapper from "../components/Container/Wrapper"
import RoundedBottomBG from "../img/round-bottomBIG.svg"
import { themeExtension } from "../styles/theme"

const RoundedBottom = styled(Box)`
  z-index: 1;
  background: url(${props => props.transition}) bottom center no-repeat,
    ${props => props.background};
`

export default ({ data }) => {
  const { frontmatter, body } = data.mdx
  let postDate = moment(frontmatter.date).format("DD.MMMM, YYYY")
  let featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Layout>
          <RoundedBottom
            align="center"
            background={themeExtension.gradients.dark}
            direction="column"
            transition={RoundedBottomBG}
            style={{ position: "relative" }}
          >
            <Wrapper>
              <Box
                round="small"
                pad="large"
                background="white"
                direction={size === "small" ? "column-reverse" : "row"}
                style={
                  size === "small"
                    ? {
                        marginTop: 50,
                        marginBottom: -70,
                        boxShadow: themeExtension.shadows.graySoft,
                        zIndex: 2,
                      }
                    : {
                        marginBottom: -150,
                        boxShadow: themeExtension.shadows.graySoft,
                        zIndex: 2,
                      }
                }
              >
                <Box justify="center" direction="column" basis="1/3">
                  <Paragraph
                    color="dark-5"
                    size="small"
                    style={{ textTransform: "uppercase" }}
                  >
                    {frontmatter.category}
                  </Paragraph>
                  <Heading
                    level="2"
                    color="brand"
                    margin={{ vertical: "none" }}
                  >
                    {frontmatter.title}
                  </Heading>
                  <Paragraph color="dark-2" size="small">
                    {postDate}, by {frontmatter.autor}
                  </Paragraph>
                </Box>
                <Box
                  basis="2/3"
                  justify="center"
                  pad={size === "small" ? {} : { left: "xsmall" }}
                >
                  <Img fluid={featuredImgFluid} />
                </Box>
              </Box>
            </Wrapper>
          </RoundedBottom>

          <Wrapper
            background={themeExtension.colors.backgrounds.light}
            pad={{ top: "none", horizontal: "medium" }}
          >
            <Box pad={{ top: "large" }} overflow="hidden">
              <MDXRenderer>{body}</MDXRenderer>
            </Box>
          </Wrapper>
        </Layout>
      )}
    </ResponsiveContext.Consumer>
  )
}

export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        date
        category
        title
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
  }
`
