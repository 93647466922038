import React from "react"
import { Link as GLink } from "gatsby"
import { Heading, Paragraph } from "grommet"
import { theme } from "../styles/theme"
import styled from "styled-components"

const checkColorMode = colormode =>
  colormode ? theme.global.colors.text.dark : theme.global.colors.text.light

const LinkStyles = styled(GLink)`
  color: ${props => checkColorMode(props.colormode)} !important;
  text-decoration: none;
  font-weight: 500 !important;
  &:hover {
    color: ${props => theme.global.colors[props.activecolor]} !important;
  }
`

const Link = ({
  activecolor = "accent-1",
  place = "activeLinkDefault",
  light,
  size,
  title,
  to,
  heading = false,
  ...rest
}) => (
  <LinkStyles
    to={to}
    colormode={light}
    activecolor={activecolor}
    activeClassName={place}
  >
    {heading ? (
      <Heading {...rest} level="2" size={size}>
        {title}
      </Heading>
    ) : (
      <Paragraph {...rest} size={size}>
        {title}
      </Paragraph>
    )}
  </LinkStyles>
)

export default Link
