import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, ResponsiveContext } from "grommet"

import "../styles/layout.css"
import Header from "./Header"
import Footer from "./Footer"
import Sidebar from "./Sidebar"

const Layout = ({ children, background }) => {
  const data = useStaticQuery(graphql`
    query SiteMetaQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          {size === "small" ? <Sidebar size={size} /> : null}
          <Header
            name="header"
            screenSize="small"
            title={data.site.siteMetadata.title}
          />
          <main id="page-wrap" style={{ height: "100%" }}>
            <Box direction="column" background={background}>
              {children}
            </Box>
            <Footer name="footer" />
          </main>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Layout
