import React from "react"
import { Box, Paragraph, ResponsiveContext, Text, Heading } from "grommet"
import { LinkedinOption, Twitter } from "grommet-icons"

import Link from "./Link"
import IconButton from "./Buttons/IconButton"
import PeriPlusLogoDark from "../assets/PeriPlusLogo_Dark.svg"
import { themeExtension } from "../styles/theme"

const FooterChild = ({ boxSize, children, title }) => (
  <Box basis={boxSize}>
    <Heading
      level="3"
      size="small"
      margin={{ bottom: "small" }}
      style={{ fontWeight: 700 }}
    >
      {title}
    </Heading>
    {children}
  </Box>
)

const footerRoutes = [
  {
    category: "Spoor",
    routes: [
      {
        title: "Products",
        to: "/pricing",
        external: false,
      },
      {
        title: "Pricing",
        to: "/pricing",
        external: false,
      },
      {
        title: "FAQ",
        to: "/faq",
        external: false,
      },
    ],
  },
  // {
  //   category: "Commercial",
  //   routes: [
  //     {
  //       title: "Blog",
  //       to: "#",
  //       external: false,
  //     },
  //     {
  //       title: "Press",
  //       to: "#",
  //       external: false,
  //     },
  //   ],
  // },
  {
    category: "PeriPlus AG",
    routes: [
      {
        title: "The Company",
        to: "https://www.periplus.ch",
        external: true,
      },
      // {
      //   title: "Careers",
      //   to: "#",
      //   external: false,
      // },
    ],
  },
]

const footerChildBoxSize = size => (size === "small" ? "full" : "1/4")
const footerCredentialsBoxSize = size => (size === "small" ? "full" : "1/2")
const centerJustificationRow = size => (size === "small" ? "center" : "end")
const centerAlignCol = size => (size === "small" ? "center" : "start")
const logoHeight = size => (size === "small" ? "70px" : "100%")
const logoInnerPadding = size => (size === "small" ? "20%" : "0")
const logoPadding = size => (size === "small" ? "medium" : "")

const Footer = ({ name }) => (
  <ResponsiveContext.Consumer>
    {size => (
      <Box
        background={themeExtension.colors.backgrounds.light}
        direction="row"
        pad={{
          top: "xlarge",
          bottom: "none",
          horizontal: "medium",
        }}
        justify="center"
      >
        <Box direction="column" width="xlarge" justify="between">
          <Box
            pad={{ horizontal: "medium", vertical: logoPadding(size) }}
            direction="row"
          >
            <Box pad={{ right: logoInnerPadding(size) }}>
              <PeriPlusLogoDark width="100%" height={logoHeight(size)} />
            </Box>
          </Box>
          <Box wrap={true} direction="row" justify="between" pad="medium">
            {footerRoutes.map((category, i) => (
              <FooterChild
                boxSize={footerChildBoxSize(size)}
                title={category.category}
                key={i}
              >
                {category.routes.map(routeItem =>
                  routeItem.external ? (
                    <a
                      href={routeItem.to}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footerLink"
                      key={routeItem.title}
                    >
                      <Paragraph size="small">{routeItem.title}</Paragraph>
                    </a>
                  ) : (
                    <Link
                      activecolor="accent-1"
                      size="small"
                      to={routeItem.to}
                      title={routeItem.title}
                      key={routeItem.title}
                    />
                  )
                )}
              </FooterChild>
            ))}

            {size === "small" ? null : (
              <>
                <FooterChild boxSize={footerChildBoxSize(size)} />
                <FooterChild boxSize={footerChildBoxSize(size)} />
              </>
            )}
          </Box>
          <Box
            direction="row-reverse"
            justify="between"
            pad="medium"
            wrap={true}
          >
            <Box
              direction="row"
              justify={centerJustificationRow(size)}
              basis={footerCredentialsBoxSize(size)}
            >
              <Box pad={{ right: "medium" }}>
                <a
                  href="https://twitter.com/PeriPlusAG"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton>
                    <Twitter />
                  </IconButton>
                </a>
              </Box>
              <Box>
                <a
                  href="https://linkedin.com/company/periplus-ag"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton>
                    <LinkedinOption />
                  </IconButton>
                </a>
              </Box>
            </Box>

            <Box
              align={centerAlignCol(size)}
              direction="column"
              basis={footerCredentialsBoxSize(size)}
              justify="center"
              pad={{ vertical: "medium" }}
            >
              <Text size="xsmall">
                © 2020 PeriPlus AG. All rights reserved.
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    )}
  </ResponsiveContext.Consumer>
)

export default Footer
