import React from "react"
import { Box } from "grommet"

const Wrapper = ({
  children,
  background,
  height,
  justify = "center",
  direction = "column",
  style = {},
  pad = {
    vertical: "xlarge",
    horizontal: "medium",
  },
  overflow,
  ...rest
}) => (
  <Box
    align="center"
    style={style}
    background={background}
    direction="column"
    overflow={overflow}
    pad={pad}
  >
    <Box
      direction={direction}
      height={height}
      pad="medium"
      width="xlarge"
      justify={justify}
      {...rest}
    >
      {children}
    </Box>
  </Box>
)

export default Wrapper
