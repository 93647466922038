import React, { useState } from "react"

import { Box, Text } from "grommet"
import { slide as Menu } from "react-burger-menu"
import { HamburgerCollapse } from "react-animated-burgers"
import { LinkedinOption, Twitter } from "grommet-icons"

import "../styles/layout.css"
import Link from "./Link"
import IconButton from "./Buttons/IconButton"

const routes = [
  {
    path: "/",
    title: "Spoor",
  },
  {
    path: "/pricing",
    title: "Pricing",
  },
  {
    path: "/faq",
    title: "FAQ",
  },
]

const Sidebar = () => {
  const [isActive, setIsActive] = useState(false)

  const closeAllMenusOnEsc = e => {
    e = e || window.event

    if (e.key === "Escape" || e.keyCode === 27) {
      setIsActive(false)
    }
  }

  if (isActive) {
    window.onscroll = function() {
      window.scrollTo(0, 0)
    }
  }

  if (!isActive) {
    window.onscroll = function() {}
  }

  return (
    <div id="outer-container" style={{ height: "100%", overflow: "hidden" }}>
      <HamburgerCollapse
        style={{ position: "absolute", zIndex: 1000002, top: 34, right: 34 }}
        isActive={isActive}
        buttonWidth={25}
        toggleButton={() => setIsActive(!isActive)}
        barColor={isActive ? "#555555" : "#F8F8F8"}
      />
      <Menu
        pageWrapId={"page-wrap"}
        isOpen={isActive}
        customBurgerIcon={false}
        customCrossIcon={false}
        customOnKeyDown={closeAllMenusOnEsc}
        outerContainerId={"outer-container"}
        disableOverlayClick={() => setIsActive(false)}
        right
      >
        <Box direction="column" flex="grow" pad="xlarge" background="light-2">
          <Box flex="grow" justify="center">
            {routes.map(route => (
              <Link
                key={route.title}
                activecolor="accent-1"
                size="large"
                heading
                to={route.path}
                title={route.title}
                margin={{ vertical: "small" }}
              />
            ))}
          </Box>
          <Box>
            <Box direction="row">
              <Box pad={{ right: "large" }}>
                <a
                  href="https://twitter.com/PeriPlusAG"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton>
                    <Twitter />
                  </IconButton>
                </a>
              </Box>
              <Box>
                <a
                  href="https://linkedin.com/company/periplus-ag"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton>
                    <LinkedinOption />
                  </IconButton>
                </a>
              </Box>
            </Box>
            <Box
              direction="column"
              justify="center"
              pad={{ vertical: "medium" }}
            >
              <Text size="xsmall">© 2020 PeriPlus AG.</Text>
            </Box>
          </Box>
        </Box>
      </Menu>
    </div>
  )
}

export default Sidebar
