// Naming: screenSize (at which its triggered) - Size Else otherSize / screenSize - Size If otherSize

export const smLargeELSEXsmall = size => (size === "small" ? "large" : "xsmall")

export const smLargeELSEMedium = size => (size === "small" ? "large" : "medium")

export const smFullELSEEmpty = size => (size === "small" ? "full" : "")

export const smFullELSEHalve = size => (size === "small" ? "full" : "1/2")

export const smColumnELSERow = size => (size === "small" ? "column" : "row")

export const smDisplayNone = size =>
  size === "small" ? { display: "none" } : {}
