import React from "react"
import styled from "styled-components"
import { Box, ResponsiveContext } from "grommet"
import { Link as GLink } from "gatsby"

import Link from "./Link"
import { smLargeELSEXsmall } from "./../helpers/breakpoints"
import SpoorLogoVertical from "../assets/VerticalLogo.svg"

const NavbarBox = styled(Box)`
  position: absolute;
  z-index: 3;
  width: 100%;
`

const routes = [
  {
    path: "/",
    title: "Spoor",
  },
  {
    path: "/pricing",
    title: "Pricing",
  },
  {
    path: "/faq",
    title: "FAQ",
  },
]

const Header = ({ title }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <NavbarBox direction="row" justify="center">
          <Box
            pad={{ horizontal: "medium", vertical: smLargeELSEXsmall(size) }}
          >
            <Box width="xlarge" pad={{ horizontal: "medium" }} direction="row">
              <Box alignSelf="center" basis="1/4">
                <GLink to="/">
                  <SpoorLogoVertical />
                </GLink>
              </Box>
              {size === "small" ? null : (
                <>
                  <Box justify="center" basis="2/4" direction="row">
                    {routes.map((route, i) => (
                      <Box pad="medium" key={i}>
                        <Link
                          activecolor="accent-2"
                          place="activeHeader"
                          size="large"
                          to={route.path}
                          title={route.title}
                          margin={{ vertical: "small" }}
                          light="true"
                        />
                      </Box>
                    ))}
                  </Box>
                  <Box
                    align="center"
                    justify="end"
                    basis="1/4"
                    direction="row"
                  ></Box>
                </>
              )}
            </Box>
          </Box>
        </NavbarBox>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Header
